import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import { rhythm } from "../utils/typography"

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        return (
          <>
            <Container
              style={{
                display: 'flex',
                width: '100%',
              }}
            >
              <HeadlineContainer  
                style={{
                  width: '100%',
                  padding: rhythm(1.5),
                }}
              >
                <Headline>
                  <span>Creating user&#8209;centered services and digital products</span>
                </Headline>
              </HeadlineContainer>
              <StyledImage fluid={data.avatar.childImageSharp.fluid} />
            </Container>
            <div
              style={{
                width: '100%',
                padding: rhythm(1.5),
              }}
            >
              <TextContainer>
                <Text>Hi, I'm Alyssa. I am a <span className='bold color'>frontend developer</span> with a background in <span className='bold color'>instructional design and service design in Southeast Asia</span>. I'm passionate about creating impactful services and digital products.</Text>
                <Text>Whenever possible, I strive for co-creation and high levels of qualitative research to understand user needs and design solutions towards the root of those needs. If you're working on a user-centered product or service, let's connect!</Text>
                <Text>
                  <BioSpan color>Current Fixations:</BioSpan> I spend my days creating new features for <a href="https://goop.com/">goop.com</a> with a team of software engineers, UX designers, and product managers. 
                  Outside of the office, you can find me attending design thinking conferences and reading up on human behavior and psychology.
                </Text>
                <Text>
                  <BioSpan color={true}>Previous Work:</BioSpan> 
                  <ul>
                    <li>
                    I served as a Princeton in Asia Fellow in Thailand, Lao PDR, and Myanmar, first as a secondary school educator and then as a field researcher / design strategist, at times going deep into the field to assess user needs and bring that lens back to HQs.
                    </li>
                    <li>
                    In 2017, I applied my ethnographic research in Lao PDR to pilot a social venture <a href="https://sangnamgan.wordpress.com/">SangNamGan</a>, committed to empowering Lao villagers to co-invest in their communities via a one-for-one funding model.
                    </li>
                  </ul>
                </Text>
                <Text>
                  <BioSpan color>Education:</BioSpan> I graduated from Princeton University with an A.B. in Politics <span className='italic'>(International Relations)</span> and a Certificate in Chinese Language and Culture. My thesis and independent research focused on the intersection of technology, society, and politics.
                </Text>
                <Text>Let's connect. You can reach me at <span className='bold break'>alyssaatain@gmail.com</span>. I would love to hear from you!</Text>
              </TextContainer>
            </div>
          </>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  @media (min-width: 769px) {
    flex-direction: unset;
  }
`

const HeadlineContainer = styled.div`
  background-color: #f9a83d;
  position: relative;
  text-transform: uppercase;

  @media (min-width: 769px) {
    flex: 1 0 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Headline = styled.h2`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 8vw;
  
  span.people {
    font-style: italic;
    font-family: Montserrat, Georgia, serif;
    display: inline-block;
    font-size: 6vw;

    @media (min-width: 769px) {
      font-size: 5vw;
    }

    @media (min-width: 1100px) {
      font-size: 55px
    }
  }

  @media (min-width: 769px) {
    font-size: 4.8vw;
  }

  @media (min-width: 1100px) {
    font-size: 55px
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
  max-height: 450px;

  @media (min-width: 769px) {
    flex: 0 0 45%;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Text = styled.p`  
  a {
    color: #000;
    font-weight: 600;

    :hover {
      color: #f9a83d;
    }
  }

  span.bold {
    font-weight: 600;
  }

  span.color {
    color: #f9a83d;
  }

  span.underline {
    text-decoration: underline;
  }

  span.break {
    word-break: break-word;
  }

  span.italic {
    font-style: italic;
  }

  :last-child {
    margin-bottom: 0;
  }
`;

const BioSpan = styled.span`
  font-weight: 600;
  font-size: 18px;
  color: ${props => props.color ? '#f9a83d' : '#000'};
  text-transform: ${props => props.color ? 'uppercase' : 'none'};
`;

export default Bio
