import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"

function PhotoGrid(props) {
  const { className } = props;
  return (
    <StaticQuery
      query={photoQuery}
      render={data => {
        return (
          <Container 
            className={className}
          >
            <div className="row">
                <div className="column">
                    {data.col1.edges.map(({ node }, i) => {
                        return <div><StyledImage key={i} fluid={node.childImageSharp.fluid} /></div>
                    })}
                </div>
                <div className="column">
                    {data.col2.edges.map(({ node }, i) => {
                        return <StyledImage key={i} fluid={node.childImageSharp.fluid} />
                    })}
                </div>  
                <div className="column">
                    {data.col3.edges.map(({ node }, i) => {
                        return <StyledImage key={i} fluid={node.childImageSharp.fluid} />
                    })}
                </div>
                <div className="column">
                    {data.col4.edges.map(({ node }, i) => {
                        return <StyledImage key={i} fluid={node.childImageSharp.fluid} />
                    })}
                </div>
            </div>
        </Container>
        )
      }}
    />
  )
}

const photoQuery = graphql`
    query photoGridQuery {
        col1: allFile(filter: { absolutePath: { regex: "/photoGrid-col1/" } }) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        col2: allFile(filter: { absolutePath: { regex: "/photoGrid-col2/" } }) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        col3: allFile(filter: { absolutePath: { regex: "/photoGrid-col3/" } }) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        col4: allFile(filter: { absolutePath: { regex: "/photoGrid-col4/" } }) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`

const Container = styled.div`
    display: flex;
    width: 100%;

    .row {
        display: -ms-flexbox; /* IE10 */
        display: flex;
        -ms-flex-wrap: wrap; /* IE10 */
        flex-wrap: wrap;
        width: 100%;
    }

    /* Create four equal columns that sits next to each other */
    .column {
        -ms-flex: 25%; /* IE10 */
        flex: 25%;
        max-width: 25%;
        padding: 0 4px;
    }

    .column div {
        margin-top: 2px;
        vertical-align: middle;
    }

    /* Responsive layout - makes a two column-layout instead of four columns */
    @media screen and (max-width: 769px) {
        .column {
            -ms-flex: 50%;
            flex: 50%;
            max-width: 50%;
        }
    }

    /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
    @media screen and (max-width: 400px) {
        .column {
            -ms-flex: 100%;
            flex: 100%;
            max-width: 100%;
        }
    }
`;

const StyledImage = styled(Image)`
  width: 100%;
  margin-bottom: 10px;
//   object-fit: cover;
//   transition: transform 400ms ease-out;

//   :hover {
//     transform: scale(1.15);
//   }      
`;

export default PhotoGrid
