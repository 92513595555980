import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Bio from "../components/bio"
import PhotoGrid from "../components/photoGrid"

class IndexPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO
          title="Alyssa Atain"
          keywords={[`gatsby`, `javascript`, `react`]}
        />
        <Bio />
        <StyledPhotoGrid />
      </Layout>
    )
  }
}


const StyledPhotoGrid = styled(PhotoGrid)`
  margin-bottom: 40px;
`;

export default IndexPage
